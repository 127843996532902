import React, { Component } from 'react';
import { array, bool, func, object, shape, string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import { parse, stringify } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';

import metaImage from '../../assets/ogp.jpg';
import { TopbarContainer } from '../../containers';
import { Footer, PageBootstrap } from '../../components';

import { FormattedMessage } from '../../util/reactIntl';
import Container from 'react-bootstrap/Container';

import { findOptionsForSelectFilter } from '../../util/search';
import { pickSearchParamsOnly, validURLParamsForExtendedData } from './SearchPage.helpers';
import MainPanel from './MainPanel';
import './searchpage.scss';


export class SearchPageComponent extends Component {
  render() {
    const {
      intl,
      listings,
      guides,
      filterConfig,
      sortConfig,
      history,
      location,
      onManageDisableScrolling,
      pagination,
      searchInProgress,
      searchListingsError,
      searchParams,
      currentUser,
      noSearch,
    } = this.props;
    // eslint-disable-next-line no-unused-vars
    const { page, ...searchInURL } = parse(location.search);

    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
    const urlQueryParams = pickSearchParamsOnly(searchInURL, filterConfig, sortConfig);

    // Page transition might initially use values from previous search
    const urlQueryString = stringify(urlQueryParams);
    const paramsQueryString = stringify(
      pickSearchParamsOnly(searchParams, filterConfig, sortConfig)
    );
    const searchParamsAreInSync = urlQueryString === paramsQueryString;

    const validQueryParams = validURLParamsForExtendedData(searchInURL, filterConfig);

    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
    const schemaImage = `${config.canonicalRootURL}${metaImage}`;

    const areasOptions = findOptionsForSelectFilter('prefectures', filterConfig);

    const searchedStyle = urlQueryParams && urlQueryParams.pub_styles?.split(':')[1];
    const stylesOptions = findOptionsForSelectFilter('styles', filterConfig);

    const searchedActivities = urlQueryParams && urlQueryParams.pub_activities?.split(':')[1];
    const activityOptions = findOptionsForSelectFilter('activities', filterConfig);

    const searchedArea = validQueryParams && validQueryParams.pub_area;
    const StaticTitle = () => {
      if (noSearch) {
        const title =
          (searchedStyle && stylesOptions.find(s => s.key === searchedStyle).label) ||
          (searchedArea && areasOptions.find(s => s.key === searchedArea).label) ||
          (searchedActivities && activityOptions.find(s => s.key === searchedActivities).label);
        return <h2 className="searchResultTitle">{title}</h2>;
      } else {
        return null;
      }
    };

    return (
      <PageBootstrap
        description={schemaDescription}
        title={schemaTitle}
        facebookImages={[{ url: metaImage }]}
        twitterImages={[{ url: `${config.canonicalRootURL}${metaImage}` }]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'GuidesPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <TopbarContainer
          currentPage="SearchPage"
          currentSearchParams={urlQueryParams}
          bigText={'Experiences'}
        />

        <Container className="staticPageWrapper">
          <StaticTitle />

          <MainPanel
            noSearch={noSearch}
            showActivityFilters={searchedArea != null || !noSearch}
            currentUser={currentUser}
            urlQueryParams={validQueryParams}
            listings={listings}
            guides={guides}
            searchInProgress={searchInProgress}
            searchListingsError={searchListingsError}
            searchParamsAreInSync={searchParamsAreInSync}
            onManageDisableScrolling={onManageDisableScrolling}
            pagination={pagination}
            searchParamsForPagination={parse(location.search)}
            history={history}
          />
        </Container>

        <Footer showBanner={false} />
      </PageBootstrap>
    );
  }
}

SearchPageComponent.defaultProps = {
  noSearch: false,
  listings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
  guides: []
};

SearchPageComponent.propTypes = {
  listings: array,
  onManageDisableScrolling: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  filterConfig: propTypes.filterConfig,
  currentUser: propTypes.currentUser,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    guides
  } = state.SearchPage;

  const { currentUser } = state.user;
  const pageListings = getListingsById(state, currentPageResultIds);

  return {
    currentUser,
    listings: pageListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    guides
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SearchPageComponent);

export default SearchPage;
