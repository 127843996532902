import React, { Component } from 'react';
import { array, bool, func, object, shape, string, node } from 'prop-types';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { FormattedMessage } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import { propTypes } from '../../util/types';
import {
  NamedLink,
  ActivityOptionSelector,
  PaginationLinks,
  ListingCardSimple,
} from '../../components';

import './mainpanel.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Form, Field } from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

import { findOptionsForSelectFilter } from '../../util/search';
import GuideCard from '../GuidesPage/GuideCard';

const activitiesOptions = findOptionsForSelectFilter('activities', config.custom.filters);
const prefectureOptions = findOptionsForSelectFilter('prefectures', config.custom.filters);

class MainPanel extends Component {
  constructor(props) {
    super(props);
    const urlQueryParams = this.props.urlQueryParams;
    const activity = urlQueryParams['pub_activities']?.split(':')[1];
    const area = urlQueryParams['pub_area'];
    this.state = {
      currentQueryParams: props.urlQueryParams,
      activityFilter: activity ? activity : 'all',
      prefectureFilter: area ? area : 'all',
    };

    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);
  }

  getHandleChangedValueFn(useHistoryPush) {
    const { urlQueryParams, history } = this.props;

    return updatedURLParams => {
      const updater = prevState => {
        const { address, bounds } = urlQueryParams;
        const mergedQueryParams = { ...urlQueryParams, ...prevState.currentQueryParams };

        return {
          currentQueryParams: { ...mergedQueryParams, ...updatedURLParams, address, bounds },
        };
      };

      const callback = () => {
        if (useHistoryPush) {
          const searchParams = this.state.currentQueryParams;
          history.push(
            createResourceLocatorString(
              this.props.noSearch ? 'SearchPage' : 'SearchClientPage',
              routeConfiguration(),
              {},
              searchParams
            )
          );
        }
      };

      this.setState(updater, callback);
    };
  }

  render() {
    const {
      listings,
      guides,
      searchInProgress,
      searchListingsError,
      searchParamsAreInSync,
      pagination,
      searchParamsForPagination,
      noSearch,
      showActivityFilters,
    } = this.props;

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const listingsAreLoaded = !searchInProgress && searchParamsAreInSync && hasPaginationInfo;

    const onSubmit = async pro => {
      if (!searchInProgress) {
        this.getHandleChangedValueFn(true)({ keywords: pro.searchField });
      }
    };

    const SearchForm = () => (
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="form-group input-group">
              <Field
                className="form-control m-0"
                name="searchField"
                component="input"
                defaultValue={this.state.currentQueryParams.keywords}
                disabled={searchInProgress}
              />
              <button type="submit" className={`btn btn-primary ${searchInProgress && 'disabled'}`}>
                {searchInProgress ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  <FontAwesomeIcon icon={faMagnifyingGlass}></FontAwesomeIcon>
                )}
              </button>
            </div>
          </form>
        )}
      />
    );

    const onSelectFilter = (filterType, urlParam) => {
      if (!searchInProgress) {
        return key => {
          this.setState({ [filterType]: key });
          this.getHandleChangedValueFn(true)({ [urlParam]: key !== 'all' ? key : null });
        };
      }
    };

    return (
      <>
        <Row>
          {!noSearch && (
            <Col xl={6}>
              <SearchForm />
            </Col>
          )}
        </Row>
        <Row className="my-3">
          {showActivityFilters && (
            <ActivityOptionSelector
              options={activitiesOptions}
              onSelect={onSelectFilter('activityFilter', 'pub_activities')}
              selected={this.state.activityFilter}
            />
          )}
        </Row>
        <Row className="my-3">
          {showActivityFilters && !noSearch && (
            <ActivityOptionSelector
              options={prefectureOptions}
              onSelect={onSelectFilter('prefectureFilter', 'pub_area')}
              selected={this.state.prefectureFilter}
            />
          )}
        </Row>

        <Row>
          <div>
            {searchListingsError ? (
              <h2 className={error}>
                <FormattedMessage id="SearchPage.searchError" />
              </h2>
            ) : null}
          </div>

          <SearchResultsPanel
            listings={listings}
            pagination={listingsAreLoaded ? pagination : null}
            search={searchParamsForPagination}
          />
        </Row>
        <Row>
          {guides.map(h => (
            <Col key={h.id.uuid} lg={4} md={6} sm={6} xs={12}>
              <GuideCard guide={h} />
            </Col>
          ))}
        </Row>
      </>
    );
  }
}

MainPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listings: [],
  guides: [],
  resultsCount: 0,
  pagination: null,
  searchParamsForPagination: {},
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
  showActivityFilters: true,
  noSearch: false,
};

MainPanel.propTypes = {
  className: string,
  rootClassName: string,

  urlQueryParams: object.isRequired,
  listings: array,
  guides: array,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParamsAreInSync: bool.isRequired,
  onManageDisableScrolling: func.isRequired,
  pagination: propTypes.pagination,
  searchParamsForPagination: object,
  filterConfig: propTypes.filterConfig,
  showActivityFilters: bool,
  noSearch: bool,
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const SearchResultsPanel = props => {
  const { listings, pagination, search } = props;

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks pageName="SearchPage" pageSearchParams={search} pagination={pagination} />
    ) : null;

  return (
    <>
      {listings.map(l => (
        <ListingCardSimple key={l.id.uuid} listing={l} />
      ))}
    </>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  listings: [],
  pagination: null,
  search: null,
};

SearchResultsPanel.propTypes = {
  children: node,
  listings: array,
  pagination: propTypes.pagination,
  search: object,
};

export default MainPanel;
